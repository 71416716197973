.importInput {
    width: 70px;
    text-align: center;
}
.importDate {
    width: 100px;
    text-align: center;
    padding: 2px 1px;
    line-height: 2px;
    margin: 2px 0px;
}
.inputDate {
    width: 95px;
    height: 25px;
    text-align: center;
    padding: 0px 1px;
    line-height: 0px;
    margin: 2px 2px;
}
.inputClock {
    width: 40px;
    height: 25px;
    text-align: center;
    padding: 0px 1px;
    line-height: 0px;
    margin: 2px 2px;
}

.boldh4 h4 {
    font-weight: bold;
}

.textWithNewline {
    white-space: pre-wrap;
}

.submitDiesel {
    margin: 5px 0px;
    letter-spacing: 1px;
}

.navbar-brand{
    font-size: x-small
}
.navbar-collapse{
    font-size: small;
    padding: 0;
    text-align: left;
}

.lightGreyBoxes {
    color: #777;
    border: 1px solid #aaa;
    border-radius: 5px;
    padding: 5px;
}

.readOnlyBoxAroundInput {
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.readOnlyBoxAroundInput textarea {
    border:0;
}

.fixedWidth {
    max-width: 100%;
    -width: 100%;
}

input::-ms-clear {
    display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.secondLine:nth-child(odd){
 background: #eaeaea;
}
.secondLine:nth-child(n){
 border-radius: 5px;
 border: 1px solid #eaeaea;
}

.noFocusBorder:focus {
    outline:none;
}

.secondLine input {
margin: 0 auto;
width: 90px;
text-align: center;
}


.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
}

.col-xs-15 {
    width: 20%;
    float: left;
}
@media (min-width: 768px) {
.col-sm-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}

.pr10 {
    padding-right: 10px;
}
.errormessage {
    color: red;
}

.glyphicon.fast-right-spinner {
    -webkit-animation: glyphicon-spin-r 1s infinite linear;
    animation: glyphicon-spin-r 1s infinite linear;
}

@-webkit-keyframes glyphicon-spin-r {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes glyphicon-spin-r {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}